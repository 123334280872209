import React from 'react'
import PropTypes from 'prop-types'

// Language
import fullCollectionPrice from '../lang/fullCollectionPrice'

const {
  unit,
  fullPrice,
  discountedPrice,
  discountPercentage
} = fullCollectionPrice

const Deal = ({
  cta,
  toggleModalShow,
  betweenProducts
}) => (
  <section className={`deal ${betweenProducts ? 'between-products-container' : 'margin-xxl'}`}>
    <div className={`container-lg ${betweenProducts ? 'between-products' : ''}`}>
      <div
        className="deal-container padding-l"
        style={{
          backgroundColor: cta ? '#AB56FF' : '#FBFCFF'
        }}
      >

        <div className={`backdrop ${cta ? 'dark' : 'light'}`} />

        <div className="yg grid">

          <div className="col-5 right">
            <div className="badge margin-xs-bottom">
              <img src={require(`../images/deal/discount-badge-${cta ? 'dark' : 'light'}.svg`)} alt="discount badge" />
              <span style={{ color: cta ? '#4b0094' : '#759ac9' }}>
                {'Save '}
                {discountPercentage}
              </span>
            </div>

            {
              cta ? (
                <h2
                  className="margin-xxs-bottom"
                  style={{ color: '#fff' }}
                >
                  Buy the full Jollycons collection
                </h2>
              ) : (
                <h2
                  className="margin-xxs-bottom"
                  style={{ color: '#6110B0' }}
                >
                  Buy the full Jollycons collection
                </h2>
              )
            }
            <div className="margin-m-bottom">
              <span
                className={`text-link ${cta ? 'white' : 'violet'}`}
                onClick={toggleModalShow}
                role="button"
                tabIndex={0}
              >
                What&apos;s included?
              </span>
            </div>

            <div>
              <button
                className={`btn cta ${cta ? 'light' : ''} lg`}
                onClick={toggleModalShow}
                type="button"
              >
                <span>
                  Buy Full Collection
                  <span className={`discount ${cta ? 'light' : 'dark'}`}>
                    {`${unit}${fullPrice}`}
                  </span>
                  <span className="price">
                    {`${unit}${discountedPrice}`}
                  </span>
                </span>
              </button>
            </div>

          </div>

        </div>
      </div>
    </div>
  </section>
)

Deal.propTypes = {
  cta: PropTypes.bool,
  betweenProducts: PropTypes.bool,
  toggleModalShow: PropTypes.func
}

export default Deal
