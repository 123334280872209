import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import withContext from '../../lib/withContext'

import Deal from '../../components/Deal'
import SEO from '../../components/SEO'

const releases = {
  past: [
    {
      title: 'Release 1 - Jollycons Launch (November 23 - 2018)',
      sets: [{
        name: 'Audio',
        link: '/categories/audio'
      }, {
        name: 'Devices',
        link: '/categories/devices'
      }, {
        name: 'Image Editing',
        link: '/categories/image-editing'
      }, {
        name: 'eCommerce',
        link: '/categories/ecommerce'
      }, {
        name: 'Food',
        link: '/categories/food'
      }, {
        name: 'Media Controls',
        link: '/categories/media-controls'
      }, {
        name: 'Christmas & New Year',
        link: '/categories/christmas-and-new-year'
      }, {
        name: 'Education',
        link: '/categories/education'
      }, {
        name: 'UI 1',
        link: '/categories/ui-1'
      }]
    },
    {
      title: 'Release 2 - November - 2018',
      sets: [{
        name: 'Medical',
        link: '/categories/medical'
      }]
    },
    {
      title: 'Release 3 - December - 2018',
      sets: [{
        name: 'Travel',
        link: '/categories/travel'
      }]
    },

    {
      title: 'Release 4 - December - 2018',
      sets: [{
        name: 'Space',
        link: '/categories/space'
      }]
    },
    {
      title: 'Release 5 - December - 2018',
      sets: [{
        name: 'UI 2',
        link: '/categories/ui-2'
      }]
    },
    {
      title: 'Release 6 - December - 2018',
      sets: [{
        name: 'Environment & Sustainability',
        link: '/categories/environment-and-sustainability'
      }]
    },
    {
      title: 'Release 7 - January - 2019',
      sets: [{
        name: 'Privacy & Security',
        link: '/categories/privacy-and-security'
      }]
    },
    {
      title: 'Release 8 - January - 2019',
      sets: [{
        name: 'Movie',
        link: '/categories/movie'
      }]
    },
    {
      title: 'Release 9 - January - 2019',
      sets: [{
        name: 'Business',
        link: '/categories/business'
      }]
    },
    {
      title: 'Release 10 - January - 2019',
      sets: [{
        name: 'Identity',
        link: '/categories/identity'
      }]
    },
    {
      title: 'Release 11 - January - 2019',
      sets: [{
        name: 'Furniture',
        link: '/categories/furniture'
      }]
    },
    {
      title: 'Release 12 - February - 2019',
      sets: [{
        name: 'Drinks',
        link: '/categories/drinks'
      }]
    },
    {
      title: 'Release 13 - February - 2019',
      sets: [{
        name: 'Politics',
        link: '/categories/politics'
      }]
    },
    {
      title: 'Release 14 - February - 2019',
      sets: [{
        name: 'People',
        link: '/categories/people'
      }]
    },
    {
      title: 'Release 15 - July - 2019',
      sets: [{
        name: 'Pride',
        link: '/categories/pride'
      }]
    },
    {
      title: 'Release 16 - August - 2019',
      sets: [{
        name: 'Time',
        link: '/categories/time'
      }, {
        name: 'Connectivity',
        link: '/categories/connectivity'
      }, {
        name: 'Communication',
        link: '/categories/communication'
      }, {
        name: 'Files 1',
        link: '/categories/files-1'
      }, {
        name: 'Files 2',
        link: '/categories/files-2'
      }, {
        name: 'Beauty',
        link: '/categories/beauty'
      }, {
        name: 'UI 3',
        link: '/categories/ui-3'
      }, {
        name: 'Maps & Navigation',
        link: '/categories/maps-and-navigation'
      }, {
        name: 'Arrows',
        link: '/categories/arrows'
      }, {
        name: 'Landmarks',
        link: '/categories/landmarks'
      }]
    }
  ],
  upcoming: [
  ]
}

const LicensePage = ({
  fullCollectionId,
  addVariantToCart,
  toggleModalShow,
  lineItems
}) => {
  const metaData = {
    title: 'Releases',
    image: undefined
  }

  return (
    <div className="sub-page">
      <SEO metaData={metaData} />
      <main className="margin-xl-top animated fadeInPage">
        <div className="container-md">
          <article className="grid">
            <section className="col-8 margin-xl-bottom intro">
              <h1>Releases</h1>
              <p>
                {'Jollycons is an icon collection that grows over time. New sets are added weekly. Keep an eye out on past and future releases as this page updates on a regular basis.'}
              </p>
            </section>

            <section className="grid yg center">
              <section className="col-7 section">
                <h3>Past releases</h3>
                <p>Up to today there have been the following releases:</p>
              </section>

              {
                releases.past.map(({ title, sets }, idx) => (
                  <section className="col-7 margin-m-bottom section" key={idx}>
                    <h4>{title}</h4>
                    <ul className="primary list__basic">
                      {
                        sets.map(({ name, link }, idx) => (
                          <li key={`set-${name}-${idx}`}>
                            Icon set added:
                            &nbsp;
                            <Link className="text-link violet" to={link}>
                              {name}
                            </Link>
                          </li>
                        ))
                      }
                    </ul>
                  </section>
                ))
              }

              <section className="col-7 margin-m-top section">
                <h3>Upcoming releases</h3>
                <p>
                  {'We intentionally don\'t share upcoming releases/additions to Jollycons too far ahead of time. However, the following releases/additions are scheduled to be made available:'}
                </p>
              </section>

              {
                releases.upcoming
                  .map(({ title, sets }, idx) => (
                    <section className="col-7 margin-m-bottom section" key={idx}>
                      <h4>{ title }</h4>
                      <ul className="primary list__basic">
                        {
                          sets.map(({ name, link }, idx) => (
                            <li key={`set-${name}-${idx}`}>
                              Icon set added:
                              &nbsp;
                              <Link className="text-link violet" to={link}>
                                { name }
                              </Link>
                            </li>
                          ))
                        }
                      </ul>
                    </section>
                  ))
              }

              {/* Temporary extra release item */}
              <section className="col-7 margin-m-bottom section">
                <ul className="primary list__basic">
                  <a
                    href="https://twitter.com/getJollycons"
                    className="text-link violet"
                    target="blank"
                  >
                    Please tell us what icon set you&apos;d like to have added!
                  </a>
                </ul>
              </section>
            </section>
          </article>
        </div>
      </main>
    </div>
  )
}

LicensePage.propTypes = {
  addVariantToCart: PropTypes.func,
  fullCollectionId: PropTypes.string,
  lineItems: PropTypes.array,
  toggleModalShow: PropTypes.func
}

export default withContext(LicensePage)
